import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Hero = ({ siteTitle, children }) => (
  <div className="hero">
  {children}
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
