import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { Button, Row, Col, Input } from 'antd';
import { DatePicker } from 'antd';
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero>
      <div className="hero-back"></div>
      <div className="content">
        <Row type="flex" align="middle">
          <Col sm={24} md={14}>
            <h1>Schedule anything in the world</h1>
            <p>We are building Timedash so businesses around the world can schedule anything. We include an API and an App-store so you can integrate anyway you want to.</p>
          </Col>
          <Col sm={0} md={8} offset={2} >
            
          </Col>
        </Row>
        {/*<Row gutter={8}>
              <Col span={8}>
                <Input placeholder="e-mail address" />
              </Col>
              <Col span={4}>
                <Button>Keep me posted</Button>
              </Col>
        </Row>*/}
      </div>
    </Hero>
    <div className="content">
      
    </div>
  </Layout>
)

export default IndexPage
